import React from 'react';
import Navbar from "../components/universali/Navbar";
import Footer from "../components/universali/Footer";

const InformazioniPagamentoPage = () => {
    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow flex items-center flex-col"}>
                <div className={"text-my-blue font-bold text-4xl underline decoration-my-green mt-10"}>
                    Informazioni Pagamenti
                </div>
                <div className={"px-10 mt-10"}>
                    Regius fortiss ducunt ad nuptia.
                    A falsis, fiscina raptus castor.
                    Regius indictios ducunt ad consilium.
                    Talis ususs ducunt ad impositio.
                    Pes moris, tanquam camerarius particula.
                    Fermium crederes, tanquam primus tumultumque.
                    Lacteas mori, tanquam superbus humani generis.
                    A falsis, decor mirabilis scutum.
                    Rectors volare, tanquam albus mensa!
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default InformazioniPagamentoPage;
