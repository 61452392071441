export const homeHeroInfoItaliano = {
    topTitle: "Sviluppato dagli insegnanti",
    title: "Sperimenta una piattaforma di apprendimento che ti porta al livello successivo",
    subtitle: "Programmi di formazione e sviluppo di livello mondiale sviluppati dai migliori insegnanti",
    button: "Esplora tutti i corsi",
}

export const homeHeroInfoInglese = {
    topTitle: "Developed By To Teachers",
    title: "Experience a learning platform that take you next level",
    subtitle: "World-class training and development programs developed by top teachers",
    button: "Explore All Courses",
}

export const homeHeroInfoRumeno = {
    topTitle: "Est superbus humani generis, cesaris. ",
    title: "Cum parma studere, omnes historiaes talem fortis, primus humani generises. ",
    subtitle: "Flavum, fidelis brodiums una gratia de bassus, gratis gluten. ",
    button: "Nunquam tractare racana. ",
}



