import {URL_BASE} from "./URL_BASE";
import axios from "axios";

export const modificaPagamento = (id, isPagato, token) => {
    const options = {
        url: `${URL_BASE}corsi/aggiorna_pagamento/${id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${token}`,
        },
        data: {is_pagato: isPagato}
    };
    return axios(options)
}
