import {URL_BASE} from "./URL_BASE";
import axios from "axios";
import jwtDecode from "jwt-decode";

export const getUsers = (token) => {
    const options = {
        url: `${URL_BASE}infermieri/verificati/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${token}`,
        },
    };

    return axios(options)
}


export const getUser = (token) => {
    const user_id = jwtDecode(token).user_id
    const options = {
        url: `${URL_BASE}infermieri/profilo/${user_id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
    };

    return axios(options)
}

export const addDocumenti = (data) => {
    const user_id = jwtDecode(localStorage.getItem("token")).user_id
    const options = {
        url: `${URL_BASE}auth/add-documenti/${user_id}`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
        data: data
    };

    return axios(options)
}


export const getUsersCorso = (codice_corso, token) => {
    const options = {
        url: `${URL_BASE}corsi/lista_iscritti/${codice_corso}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${token}`,
        },
    };

    return axios(options)
}
