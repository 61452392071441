import React, {useEffect, useState} from 'react';
import axios from "axios";
import {URL_BASE} from "../axios/URL_BASE";
import {getUser} from "../axios/GetUsers";
import {getCorsi} from "../axios/GetCorsi";

const TestPage = () => {

    const [user, setUser] = useState([])
    const [corso, setCorso] = useState([])

    useEffect(() => {

        getUser().then(response => {
            setUser(response.data)
        })

        getCorsi().then(res => {
            setCorso(res.data[0])
        })

    }, [])




    return (
        <div className={""}>
            <div className={"bg-my-yellow h-80 bg-opacity-20"}>
                <div>
                    {
                        user.nome
                    }
                </div>
                <div>
                    {
                        corso.nome
                    }
                </div>

            </div>
        </div>

    );
};

export default TestPage;
