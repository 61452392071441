import React from 'react';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer
            className={"flex flex-col bg-my-blue text-my-gray items-center justify-center divide-y divide-my-gray divide-opacity-20 p-2 bottom-0 w-full"}>
            <div className={"w-full p-2 flex justify-center"}>
                <Link to={"/"}>
                    {/*<img src={logo} alt="" className={"h-20"}/>*/}
                    <div className={"h-20 flex justify-center items-center font-bold text-4xl"}>
                        Logo
                    </div>

                </Link>

            </div>
            <div className={"w-full p-4"}>
                <p className={"text-primary"}>© 2022 InfoPlus</p>
            </div>
        </footer>
    );
};

export default Footer;
