import './App.css';
import {HashRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import CorsiPage from "./pages/corsi/CorsiPage";
import ContattiPage from "./pages/ContattiPage";
import CorsoSingoloPage from "./pages/corsi/CorsoSingoloPage";
import TestPage from "./pages/TestPage";
import {useDispatch, useSelector} from "react-redux";
import Dashboard from "./pages/Gestionale/Dashboard";
import AboutPage from "./pages/AboutPage";
import EmailVerificataPage from "./pages/link_verifica/EmailVerificataPage";
import ProfiloPage from "./pages/ProfiloPage";
import {useEffect} from "react";
import {verifyToken} from "./axios/VerifyToken";
import jwtDecode from "jwt-decode";
import {adminLogin, login, logout} from "./redux/actions/logActions";
import CambiaPasswordPage from "./pages/CambiaPasswordPage";
import SendNewVerifyLinkPage from "./pages/link_verifica/SendNewVerifyLinkPage";
import InformazioniPagamentoPage from "./pages/InformazioniPagamentoPage";
import GestioneCorsoPage from "./pages/Gestionale/GestioneCorsoPage";
import AggiungiIscrittoPage from "./pages/Gestionale/AggiungiIscrittoPage";
import AggiungiCorsoPage from "./pages/Gestionale/AggiungiCorsoPage";

function App() {

    const token = localStorage.getItem("token")
    const dispatch = useDispatch()


    useEffect(() => {
        verifyToken(token).then(() => {
            if (jwtDecode(token).is_staff) {
                dispatch(adminLogin())
            } else {
                dispatch(login())
            }
        }).catch(() => {
            dispatch(logout())
        })
    }, [])

    const isLogged = useSelector(state => state.logReducer)

    const PrivateRouteAdmin = ({children}) => {
        return isLogged.is_staff ? children : <Navigate to="/login"/>;
    }

    const PrivateRouteUser = ({children}) => {
        return isLogged.is_user ? children : <Navigate to="/login"/>;
    }

    return (
        <Router>
            <Routes>
                <Route path={"/"} element={<HomePage/>}/>
                <Route path={"/login"} element={<LoginPage/>}/>
                <Route path={"/corsi"} element={<CorsiPage/>}/>
                <Route path={"/corsi/:id"} element={<CorsoSingoloPage/>}/>
                <Route path={"/contatti"} element={<ContattiPage/>}/>
                <Route path={"/about"} element={<AboutPage/>}/>
                <Route path={"/new_link"} element={<SendNewVerifyLinkPage/>}/>
                <Route path={"/info_pagamenti"} element={<InformazioniPagamentoPage/>}/>
                <Route path={"/email_verificata/:token"} element={<EmailVerificataPage/>}/>
                <Route path={"/reset_password/:token/:uidb64"} element={<CambiaPasswordPage/>}/>


                <Route path={"/test"} element={<TestPage/>}/>

                <Route
                    path="/dashboard"
                    element={
                        <PrivateRouteAdmin>
                            <Dashboard/>
                        </PrivateRouteAdmin>
                    }
                />
                <Route
                    path="/gestione_corso/:id"
                    element={
                        <PrivateRouteAdmin>
                            <GestioneCorsoPage/>
                        </PrivateRouteAdmin>
                    }
                />
                <Route
                    path="/aggiungi_iscritto"
                    element={
                        <PrivateRouteAdmin>
                            <AggiungiIscrittoPage/>
                        </PrivateRouteAdmin>
                    }
                />
                {/*<Route*/}
                {/*    path="/aggiungi_corso"*/}
                {/*    element={*/}
                {/*        <PrivateRouteAdmin>*/}
                {/*            <AggiungiCorsoPage/>*/}
                {/*        </PrivateRouteAdmin>*/}
                {/*    }*/}
                {/*/>*/}


                <Route
                    path="/profilo"
                    element={
                        <PrivateRouteUser>
                            <ProfiloPage/>
                        </PrivateRouteUser>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
