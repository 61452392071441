import React, {useEffect, useState} from 'react';
import Navbar from "../../components/universali/Navbar";
import Footer from "../../components/universali/Footer";
import {useParams} from "react-router-dom";
import {getCorsoSingolo} from "../../axios/GetCorsi";
import {getUsersCorso} from "../../axios/GetUsers";
import {modificaPagamento} from "../../axios/ModificaPagamento";
import {useSelector} from "react-redux";

const GestioneCorsoPage = () => {

    const {id} = useParams()
    const [corso, setCorso] = useState({})
    const [iscritti, setIscritti] = useState([])
    const [filter, setFilter] = useState("")
    const {token} = useSelector(state => state.tokenReducer)

    const getIscrittiCorso = () => {
        getCorsoSingolo(id).then(res => {
            setCorso(res.data)
            getUsersCorso(res.data.codice_corso, token).then(res => {
                setIscritti(res.data)
            })
        })
    }

    const changeIsPagato = (e, id, isPagato) => {
        e.preventDefault()
        modificaPagamento(id, isPagato, token).then(() => {
            getIscrittiCorso()
        })
    }


    useEffect(() => {
        getIscrittiCorso()
    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow"}>
                <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
                    <h1 className={"text-center text-4xl font-bold mb-8"}>Iscritti Corso: {corso.nome}</h1>
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className={"bg-my-gray w-full"}>
                            <input
                                type="text"
                                className={"bg-transparent w-full outline outline-none py-2 px-4 rounded-t-md"}
                                placeholder={"Filtra"}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </div>
                        <div className="shadow border-b border-my-gray sm:rounded-lg">
                            <table className="min-w-full divide-y divide-my-gray">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Nome
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Cognome
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Email
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Telefono
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Pagato
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Modifica
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-my-gray">
                                {
                                    iscritti.filter(iscrizione =>
                                        iscrizione.user.nome.toLowerCase().includes(filter.toLowerCase()) ||
                                        iscrizione.user.cognome.toLowerCase().includes(filter.toLowerCase()) ||
                                        iscrizione.user.email.toLowerCase().includes(filter.toLowerCase())
                                    ).map((iscrizione) => (
                                        <tr key={iscrizione.user.id}>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div
                                                    className="text-sm font-medium text-my-blue">{iscrizione.user.nome}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div
                                                    className="text-sm font-medium text-my-blue">{iscrizione.user.cognome}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div
                                                    className="text-sm font-medium text-my-blue">{iscrizione.user.email}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-my-blue">
                                                {iscrizione.user.telefono}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-my-blue">
                                                {
                                                    iscrizione.is_pagato ? (
                                                        <div>
                                                            SI
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            NO
                                                        </div>
                                                    )
                                                }
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div
                                                    className="hover:font-semibold text-my-green underline cursor-pointer"
                                                    onClick={(e) => changeIsPagato(e, iscrizione.id, !iscrizione.is_pagato)}
                                                >
                                                    Modifica
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default GestioneCorsoPage;
