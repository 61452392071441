import React, {useEffect, useState} from 'react';
import {InfoTeam, TeamStoryInglese, TeamStoryItaliano, TeamStoryRumeno} from "../../constants/About/InfoTeam";
import {useSelector} from "react-redux";

const AboutTeam = () => {

    const [info, setInfo] = useState(TeamStoryItaliano)

    const language = useSelector(state => state.languageReducer)

    useEffect(() => {
        switch (language) {
            case "Italiano":
                setInfo(TeamStoryItaliano)
                break
            case "Inglese":
                setInfo(TeamStoryInglese)
                break
            case "Rumeno":
                setInfo(TeamStoryRumeno)
                break
            default:
                setInfo(TeamStoryItaliano)
        }
    }, [language])

    return (
        <div className="flex lg:flex-row flex-col justify-between gap-8 pt-12">
            <div className="w-full lg:w-5/12 flex flex-col justify-center">
                <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">{info.titolo}</h1>
                <p className="font-normal text-base leading-6 text-gray-600 ">
                    {info.descrizione}
                </p>
            </div>
            <div className="w-full lg:w-8/12 lg:pt-8">
                <div className="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
                    {
                        InfoTeam.map(docente => (
                            <div className="p-4 pb-6 flex justify-center flex-col items-center" key={docente.id}>
                                <img className="md:block hidden object-center object-cover h-40 w-40 rounded-md"
                                     src={docente.foto} alt="Alexa featured Img"/>
                                <img className="md:hidden block object-center object-cover h-40 w-60 rounded-md"
                                     src={docente.foto} alt="Alexa featured Img"/>
                                <p className="font-medium text-xl leading-5 text-gray-800 mt-4">{docente.nome} {docente.cognome}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default AboutTeam;
