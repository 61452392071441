import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone, faMapLocation} from "@fortawesome/free-solid-svg-icons";

const InfoContatti = () => {
    return (
        <div className={"flex lg:my-10"}>
            <div className={"flex-2 flex flex-col lg:flex-row text-center p-4 divide-y lg:divide-y-0 lg:divide-x divide-my-blue divide-opacity-50"}>
                <div className={"flex-1 py-3"}>
                    <FontAwesomeIcon icon={faPhone} className={"text-4xl font-semibold text-my-green"}/>
                    <div className={"py-3"}>
                       <p>+458 123 657 2324</p>
                       <p>+458 123 657 2324</p>
                    </div>
                </div>
                <div className={"flex-1 py-3"}>
                    <FontAwesomeIcon icon={faEnvelope} className={"text-4xl font-semibold text-my-green"}/>
                    <div className={"py-3"}>
                        <p>your-mail@gmail.com</p>
                        <p>your-mail@gmail.com</p>
                    </div>
                </div>
                <div className={"flex-1 py-3"}>
                    <FontAwesomeIcon icon={faMapLocation} className={"text-4xl font-semibold text-my-green"}/>
                    <div className={"py-3"}>
                        <p>PO Box 16122 Collins Street West</p>
                        <p>PO Box 16122 Collins Street West</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoContatti;
