import {courseCardsItaliano} from "./CourseCards";

export const primoCorsoItaliano = {
    card: courseCardsItaliano[0],
    descrizione: "This tutorial will help you learn quickly and thoroughly. Lorem ipsum is dummy text used in laying out print, graphic or web designs",
    dettagli : "This tutorial will help you learn quickly and thoroughly. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.\n" +
        "\n" +
        "This tutorial will help you learn quickly and thoroughly. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.",
    skill: [
        "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting",
        "Learn how perspective works and how to incorporate it into your art",
        "Learn and apply industry-standard drawing techniques",
        "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting",
        "Learn how perspective works and how to incorporate it into your art",
        "Learn and apply industry-standard drawing techniques"
    ],
}
export const secondoCorsoItaliano = {
    card: courseCardsItaliano[1],
    descrizione: "This tutorial will help you learn quickly and thoroughly. Lorem ipsum is dummy text used in laying out print, graphic or web designs",
    dettagli : "This tutorial will help you learn quickly and thoroughly. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.\n" +
        "\n" +
        "This tutorial will help you learn quickly and thoroughly. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.",
    skill: [
        "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting",
        "Learn how perspective works and how to incorporate it into your art",
        "Learn and apply industry-standard drawing techniques",
        "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting",
        "Learn how perspective works and how to incorporate it into your art",
        "Learn and apply industry-standard drawing techniques"
    ],
}

export const terzoCorsoItaliano = {
    card: courseCardsItaliano[2],
    descrizione: "This tutorial will help you learn quickly and thoroughly. Lorem ipsum is dummy text used in laying out print, graphic or web designs",
    dettagli : "This tutorial will help you learn quickly and thoroughly. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.\n" +
        "\n" +
        "This tutorial will help you learn quickly and thoroughly. Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros.",
    skill: [
        "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting",
        "Learn how perspective works and how to incorporate it into your art",
        "Learn and apply industry-standard drawing techniques",
        "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting",
        "Learn how perspective works and how to incorporate it into your art",
        "Learn and apply industry-standard drawing techniques"
    ],
}

