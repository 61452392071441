import React, {useEffect, useState} from 'react';
import Navbar from "../../components/universali/Navbar";
import Footer from "../../components/universali/Footer";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {primoCorsoItaliano} from "../../constants/Course/InfoSingoloCorso";
import {PayPalButton} from "react-paypal-button-v2";
import {useSelector} from "react-redux";
import {getCorsiUtente, getCorsoSingolo} from "../../axios/GetCorsi";
import ufficio from "../../assets/images/Office.png"
import {setIscrizionePagata} from "../../axios/AddIscrizione";
import {getUser} from "../../axios/GetUsers";
import MetodiDiPagamento from "../../components/home/MetodiDiPagamento";

const CorsoSingoloPage = () => {

    const {id} = useParams()

    const [info, setInfo] = useState(primoCorsoItaliano)
    const [corso, setCorso] = useState({})
    const [corsoPagato, setCorsoPagato] = useState(false)
    const [success, setSuccess] = useState(false)

    const isLogged = useSelector(state => state.logReducer)
    const {token} = useSelector(state => state.tokenReducer)

    const addPayPalScript = () => {
        const clientId = "AZM-1pSJmxW8o08eL-sMe_7rJM_2rS-1lD2ZFUycNaK2cWJVd2rBZkv0hTJKuN_6-jtzDIXPiUQcZwUH"
        const script = document.createElement("script")
        script.type = "text/javascript"
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
        script.async = true
        document.body.appendChild(script)
    }



    useEffect(() => {
        window.scroll(0, 0)
        getCorsoSingolo(id).then(response => {
            setCorso(response.data)
        })


        if (isLogged.is_user) {
            getUser(token).then(res => {
                getCorsiUtente(res.data.email).then(res => {
                    const corsi = res.data
                    corsi.forEach((c) => {
                        if (c.corso.id == id) {
                            setCorsoPagato(true)
                        }
                    })
                })
            })
        }

        if (!window.paypal) {
            addPayPalScript()
        }

        if (success) {
            setIscrizionePagata(corso.id, token)
        }

    }, [success])

    const bottoniPagamento = () => {
        if (!corsoPagato) {
            if (isLogged.is_user && corso.costo !== 0) {
                return (
                    <div className={"z-0"}>
                        <PayPalButton
                            amount={corso.costo}
                            currency={"USD"}
                            onSuccess={() => setSuccess(true)}
                        />
                    </div>
                )
            } else if (isLogged.is_user && corso.costo === 0) {
                return (
                    <div className={"z-0"}>
                        <div>
                            Il corso è gratuito
                        </div>
                        <div className={"mt-4 flex justify-center"}>
                            <button
                                onClick={() => setSuccess(true)}
                                className={"bg-my-green px-4 py-2 rounded text-my-gray font-semibold text-md hover:bg-my-blue transition duration-300"}>
                                Iscriviti
                            </button>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className={"z-0"}>
                        <div>
                            Devi aver effettuato l'accesso per poter effettuare un pagamento ad
                            un corso
                        </div>
                        <div className={"mt-4 flex justify-center"}>
                            <Link to={"/login"}>
                                <button
                                    className={"bg-my-green px-4 py-2 rounded text-my-gray font-semibold text-md hover:bg-my-blue transition duration-300"}>
                                    Vai al Login
                                </button>
                            </Link>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className={"z-0"}>
                    <div className={"text-2xl"}>
                        Sei già iscritto a questo Corso
                    </div>
                </div>
            )
        }
    }


    return (
        <div>
            <div className={"flex flex-col min-h-screen"}>
                <Navbar/>
                <div className={"mt-20 flex-grow flex flex-col lg:flex-row"}>
                    <div className="flex justify-center py-9 px-6">
                        <div className="flex w-full lg:w-full flex-col lg:flex-row justify-center">
                            <div className="flex flex-2 lg:px-10 w-full flex-col justify-start items-start space-y-6">
                                <div>
                                    <p className="text-3xl lg:text-4xl font-bold text-my-blue">
                                        {corso.nome}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        {corso.descrizione}
                                    </p>
                                </div>
                                <div className={"flex justify-start items-center"}>
                                    <div>
                                        <img
                                            src={ufficio}
                                            alt=""
                                            className={"h-10 w-10 object-cover object-center rounded-full"}/>
                                    </div>
                                    <div className={"ml-4 font-semibold text-my-blue"}>
                                        {corso.docente}
                                    </div>
                                </div>
                                <div className={"flex"}>
                                    <div className={"flex flex-col py-4 space-y-4"}>
                                        <div>
                                            <h1
                                                className={"text-lg lg:text-2xl font-bold text-my-blue"}
                                            >
                                                Dettagli del corso
                                            </h1>
                                        </div>
                                        <div className={"space-y-4"}>
                                            <p>
                                                {info.dettagli}
                                            </p>
                                        </div>
                                        <div className={"flex flex-col bg-my-gray px-4 py-6 rounded-md"}>
                                            <h1 className={"text-xl font-bold text-my-blue mb-4"}>Cosa Imparerai</h1>
                                            <div className={"space-y-4"}>
                                                {
                                                    info.skill.map((txt, index) => (
                                                        <div key={index} className={"flex items-center pl-2"}>
                                                            <FontAwesomeIcon icon={faCheck}
                                                                             className={"font-semibold text-my-green mr-4"}/>{txt}
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-1 lg:px-4 bg-my-gray rounded-md w-full flex-col justify-start items-start space-y-6">
                                <div className={"flex flex-col w-full space-y-4 bg-my-gray px-4 py-6 rounded-md"}>
                                    <h1 className={"text-xl font-bold text-my-blue"}>Informazioni Pagamento</h1>
                                    <Link className={"mt-4 flex justify-center"} to={"/info_pagamenti"}>
                                        <button
                                            className={"bg-my-green px-4 py-2 rounded text-my-gray font-semibold text-md hover:bg-my-blue transition duration-300"}>
                                            Informazioni
                                        </button>
                                    </Link>
                                    {/*{*/}
                                    {/*    bottoniPagamento()*/}
                                    {/*}*/}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        </div>
    );
};

export default CorsoSingoloPage;
