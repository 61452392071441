import React, {useEffect, useState} from 'react';
import Navbar from "../../components/universali/Navbar";
import Footer from "../../components/universali/Footer";
import {useParams} from "react-router-dom";
import {emailVerify} from "../../axios/EmailVerify";

const EmailVerificataPage = () => {

    const {token} = useParams()
    const [message, setMessage] = useState("Qualcosa è andato storto")

    useEffect(() => {
        emailVerify(token).then(response => {
            setMessage(response.data.email)
            console.log(response)
        }).catch(e => {
            console.log(e)
        })
    }, [token])

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow flex flex-col items-center justify-center bg-my-gray"}>
                <div className={"text-center text-my-blue font-bold text-4xl underline decoration-my-green"}>
                    {message}
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default EmailVerificataPage;
