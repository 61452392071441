import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {adminLogin, login, logout, removeToken} from "../../redux/actions/logActions";
import {navLinksInglese, navLinksItaliano, navLinksRumeno} from "../../constants/Navbar/NavLinks";
import SelectorButton from "./SelectorButton";


const Navbar = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const language = useSelector(state => state.languageReducer)
    const [navLinks, setNavLinks] = useState(navLinksItaliano)

    const [isOpen, setOpen] = useState(false)
    const isLogged = useSelector(state => state.logReducer)

    const displayLog = () => (
        isLogged.logged ? (
            <div onClick={() => {
                dispatch(logout())
                dispatch(removeToken())
                navigate("/")
            }}>
                <h3 className={`text-xl text-my-blue font-semibold hover:underline decoration-my-green decoration-2`}>
                    Logout
                </h3>
            </div>
        ) : (
            <Link to={"/login"}>
                <h3 className={`text-xl text-my-blue font-semibold hover:underline decoration-my-green decoration-2`}>
                    Login
                </h3>
            </Link>
        )
    )

    useEffect(()=>{
        switch (language) {
            case "Italiano":
                setNavLinks(navLinksItaliano)
                break
            case "Inglese":
                setNavLinks(navLinksInglese)
                break
            case "Rumeno":
                setNavLinks(navLinksRumeno)
                break
            default:
                setNavLinks(navLinksItaliano)
        }
    }, [language])

    return (
        <header className={`p-2 bg-white shadow fixed w-full z-50 bg-opacity-70 backdrop-filter backdrop-blur-xl`}>
            <div className={"flex justify-between items-center px-2"}>
                {/*Logo*/}
                <Link to={"/"}>
                    {/*<img src={logo} alt="" className={"h-20"}/>*/}
                    <div className={"h-20 text-my-blue flex justify-center items-center font-bold text-4xl"}>
                        Logo
                    </div>
                </Link>

                {/*Link Large*/}
                <div className={"flex space-x-10 hidden items-center lg:flex"}>
                    {
                        navLinks.map(link => (
                            <Link to={link.link} key={link.id}>
                                <h3 className={`text-xl text-my-blue font-semibold hover:underline ${link.underline} decoration-2`}>
                                    {link.nome}
                                </h3>
                            </Link>
                        ))
                    }

                    {
                        isLogged.is_staff && (
                            <Link to={"/dashboard"}>
                                <h3 className={`text-xl text-my-blue font-semibold hover:underline decoration-my-green decoration-2`}>
                                    Dashboard
                                </h3>
                            </Link>
                        )
                    }
                    {
                        isLogged.is_user && (
                            <Link to={"/profilo"}>
                                <h3 className={`text-xl text-my-blue font-semibold hover:underline decoration-my-green decoration-2`}>
                                    Profilo
                                </h3>
                            </Link>
                        )
                    }
                    {
                        displayLog()
                    }
                    <SelectorButton/>
                </div>

                <div className={"flex space-x-10 visible lg:hidden"}>
                    <button className={"flex items-center justify-center"} onClick={() => setOpen(!isOpen)}>
                        {
                            !isOpen ?
                                <FontAwesomeIcon icon={faBars} className={"text-2xl text-my-blue font-semibold"}/>
                                :
                                <FontAwesomeIcon icon={faXmark} className={"text-2xl text-my-blue font-semibold"}/>
                        }
                    </button>
                </div>
            </div>
            <div>
                {
                    isOpen &&
                    <div className={"flex text-my-blue flex-col justify-center items-center gap-y-6 py-3"}>
                        {
                            navLinks.map(link => (
                                <Link to={link.link} key={link.id}>
                                    <h3 className={"text-lg font-semibold hover:underline hover:decoration-danger decoration-2"}>
                                        {link.nome}
                                    </h3>
                                </Link>
                            ))
                        }
                        {
                            isLogged.is_staff && (
                                <Link to={"/dashboard"}>
                                    <h3 className={`text-xl font-semibold hover:underline decoration-my-green decoration-2`}>
                                        Dashboard
                                    </h3>
                                </Link>
                            )
                        }
                        {
                            isLogged.is_user && (
                                <Link to={"/profilo"}>
                                    <h3 className={`text-xl text-my-blue font-semibold hover:underline decoration-my-green decoration-2`}>
                                        Profilo
                                    </h3>
                                </Link>
                            )
                        }
                        {
                            displayLog()
                        }
                        <SelectorButton/>
                    </div>
                }
            </div>

        </header>
    )
}

export default Navbar
