import axios from "axios";
import {URL_BASE} from "./URL_BASE";

export const userRegister = (data) => {
    const options = {
        url: `${URL_BASE}infermieri/registra/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: data
    };

    return axios(options)
}
