import React, {useState} from 'react';
import Navbar from "../components/universali/Navbar";
import Footer from "../components/universali/Footer";
import AboutHero from "../components/about/AboutHero";
import AboutTeam from "../components/about/AboutTeam";

const AboutPage = () => {

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow"}>
                <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
                    <AboutHero/>
                    <AboutTeam/>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default AboutPage;
