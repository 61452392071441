import React, {useEffect, useState} from 'react';
import Navbar from "../components/universali/Navbar";
import Footer from "../components/universali/Footer";
import {addDocumenti, getUser} from "../axios/GetUsers";
import {changePassword, changePasswordLoggedUser, requestTokenUidb64} from "../axios/EditPassword";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faWarning} from "@fortawesome/free-solid-svg-icons";
import {getCorsiUtente} from "../axios/GetCorsi";
import {useSelector} from "react-redux";
import {verifyToken} from "../axios/VerifyToken";

const ProfiloPage = () => {

    const [user, setUser] = useState({})
    const [token, setToken] = useState("")
    const x = useSelector(state => state.tokenReducer)


    const [positiveAlert, setPositiveAlert] = useState(false)
    const [negativeAlert, setNegativeAlert] = useState({
        status: false,
        message: ""
    })

    const [corsi, setCorsi] = useState([])


    useEffect(() => {
        getUser(x.token).then(response => {
            setUser(response.data[0])
            getCorsiUtente(response.data[0].email).then(r => {
                setCorsi(r.data)
            }).catch(e => {
                console.log(e)
            })
        })
        verifyToken(x.token).then(()=>{
            setToken(x.token)
        }).catch(e => {
            console.log(e)
        })

    }, [])

    const handleChangePassword = (e) => {
        e.preventDefault()
        if (e.target.password.value === e.target.password2.value) {
            const data = {
                new_password: e.target.password.value,
                new_re_password: e.target.password2.value,
            }
            changePasswordLoggedUser(data, token).then(() => {
                setPositiveAlert(true)
                setNegativeAlert({
                    status: false,
                    message: ""
                })
                e.target.password.value = ""
                e.target.password2.value = ""
            }).catch(() => {
                setNegativeAlert({
                    status: true,
                    message: "Il token non è più valido, richiedine un'altro"
                })
                setPositiveAlert(false)
            })
        } else {
            setNegativeAlert({
                status: true,
                message: "Le due password non coincidono"
            })
            setPositiveAlert(false)
        }
    }

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow "}>
                <div className={"flex items-center flex-col space-y-6"}>
                    <div className={"text-my-blue font-bold text-4xl underline decoration-my-green mt-10 px-10"}>
                        Benvenuto {user.nome} {user.cognome}
                    </div>
                    <div className={"flex flex-col lg:flex-row lg:space-x-6 w-full px-10 space-y-6 lg:space-y-0"}>
                        <div className={"flex-1 bg-my-gray p-5 rounded space-y-4"}>
                            <h1 className={"font-bold text-my-blue text-xl"}>Cambia Password</h1>
                            {
                                positiveAlert && (
                                    <div className={"flex justify-center items-center mt-10 mb-6"}>
                                        <div
                                            className={"bg-my-green bg-opacity-10 w-2/3 py-2 px-4 rounded-md flex justify-between"}>
                                            <div className={"flex space-x-5 items-center"}>
                                                <FontAwesomeIcon icon={faCheck}
                                                                 className={"text-xl text-my-green font-semibold"}/>
                                                <div>
                                                    <p className={"text-my-green underline"}>
                                                        Cambio password avvenuto con successo
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={"flex items-center cursor-pointer"}
                                                 onClick={() => setPositiveAlert(false)}>
                                                <FontAwesomeIcon icon={faClose}
                                                                 className={"text-xl text-my-green font-semibold"}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                negativeAlert.status && (
                                    <div className={"flex justify-center items-center mt-10 mb-6"}>
                                        <div
                                            className={"bg-my-red bg-opacity-10 w-2/3 py-2 px-4 rounded-md flex justify-between"}>
                                            <div className={"flex space-x-5 items-center"}>
                                                <FontAwesomeIcon icon={faWarning}
                                                                 className={"text-xl text-my-red font-semibold"}/>
                                                <div>
                                                    <p className={"text-my-red underline"}>
                                                        {negativeAlert.message}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={"flex items-center cursor-pointer"}
                                                 onClick={() => setNegativeAlert({
                                                     status: false,
                                                     message: ""
                                                 })}>
                                                <FontAwesomeIcon icon={faClose}
                                                                 className={"text-xl text-my-red font-semibold"}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <form onSubmit={handleChangePassword} className={"col-span-2 bg-my-green rounded-md"}>
                                <div className={"shadow p-10 space-y-6 rounded-md"}>
                                    <div className={"flex flex-col space-y-6 "}>
                                        <div>
                                            <input
                                                type={"password"}
                                                name={"password"}
                                                className={"bg-my-gray rounded outline-none py-2 px-4 w-full"}
                                                placeholder={"Nuova Password"}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <input
                                                type={"password"}
                                                name={"password2"}
                                                className={"bg-my-gray rounded outline-none py-2 px-4 w-full"}
                                                placeholder={"Ripeti Nuova Password"}
                                                required
                                            />
                                        </div>
                                        <div className={"flex justify-center"}>
                                            <button
                                                type={"submit"}
                                                className={"bg-my-blue px-4 py-2 rounded text-my-gray font-semibold hover:bg-my-gray hover:text-my-blue transition duration-300"}>
                                                Cambia Password
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className={"flex-1 bg-my-gray p-5 rounded space-y-4"}>
                            <h1 className={"font-bold text-my-blue text-xl"}>I tuoi dati</h1>
                            <div className={"flex flex-col space-y-6"}>
                                <h1 className={"text-lg text-my-blue"}>{user.nome}</h1>
                                <h1 className={"text-lg text-my-blue"}>{user.cognome}</h1>
                                <h1 className={"text-lg text-my-blue"}>{user.email}</h1>
                                <h1 className={"text-lg text-my-blue"}>{user.telefono}</h1>
                                {/*<div className={"flex w-full"}>*/}
                                {/*    {*/}
                                {/*        !documenti ? (*/}
                                {/*            <form onSubmit={handleAddDocumenti}>*/}
                                {/*                <div className={"flex flex-col lg:flex-row space-y-6 lg:space-y-0"}>*/}
                                {/*                    <div className={"space-y-3"}>*/}
                                {/*                        <p className={"text-my-blue"}>Documento di Laurea</p>*/}
                                {/*                        <input type="file" accept={"application/pdf"} name={"laurea"} required/>*/}
                                {/*                    </div>*/}
                                {/*                    <div className={"space-y-3"}>*/}
                                {/*                        <p className={"text-my-blue"}>Documento di Identità</p>*/}
                                {/*                        <input type="file" accept={"application/pdf"} name={"documento"}*/}
                                {/*                               required/>*/}
                                {/*                    </div>*/}

                                {/*                    <button type={"submit"}*/}
                                {/*                            className={"bg-my-green px-4 py-2 text-white rounded hover:bg-my-blue"}>*/}
                                {/*                        Aggiungi*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}
                                {/*            </form>*/}
                                {/*        ) : (*/}
                                {/*            <div className={"flex justify-between items-center w-full"}>*/}
                                {/*                <h1 className={"text-lg text-my-blue"}>*/}
                                {/*                    Documenti forniti correttamente*/}
                                {/*                </h1>*/}
                                {/*                <button*/}
                                {/*                    onClick={() => setDocumenti(false)}*/}
                                {/*                    className={"px-4 py-2 bg-my-green rounded hover:bg-my-blue text-white"}>*/}
                                {/*                    Modifica Documenti*/}
                                {/*                </button>*/}
                                {/*            </div>*/}

                                {/*        )*/}
                                {/*    }*/}

                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex items-center flex-col space-y-6 my-10"}>
                    <div className={"text-my-blue font-bold text-4xl underline decoration-my-green px-10"}>
                        I Miei Corsi
                    </div>
                    <div className={"flex flex-col lg:flex-row lg:space-x-5 lg:space-y-0 space-y-5"}>
                        {
                            corsi.map(iscrizione => (
                                <div className={"shadow rounded bg-my-gray w-80 lg:w-60"} key={iscrizione.id}>
                                    <div className={"p-6 flex justify-between"}>
                                        <div className={"bg-my-green bg-opacity-20 px-2 py-1 rounded text-my-green font-semibold"}>
                                            {iscrizione.corso.categoria}
                                        </div>
                                    </div>
                                    <div className={"px-6 pb-6 text-my-blue text-xl font-semibold"}>
                                        {iscrizione.corso.nome}
                                    </div>
                                    <div className={"px-6 pb-6 flex items-center"}>
                                        <div className={""}>
                                            {iscrizione.corso.docente}
                                        </div>
                                    </div>
                                    <div className={"px-6 pb-6 flex items-center"}>
                                        <div className={"bg-"}>
                                            <a
                                                href="https://www.youtube.com/watch?v=lDqlasyMJog"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className={"ml-4 bg-my-green px-4 py-2 rounded text-white hover:bg-my-blue"}
                                            >
                                                Apri in Teams
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default ProfiloPage;
