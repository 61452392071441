const languageReducer = (state = "Italiano", action) => {
    switch (action.type) {
        case "ITALIANO":
            return "Italiano"
        case "INGLESE":
            return "Inglese"
        case "RUMENO":
            return "Rumeno"
        default:
            return state
    }
}

export default languageReducer;
