export const infoContattiItaliano = {
    titolo: "Contattaci",
    email: "Email",
    oggetto: "Oggetto",
    messaggio: "Messaggio",
    bottone: "Invia"
}

export const infoContattiInglese = {
    titolo: "Contact Us",
    email: "Email",
    oggetto: "Subject",
    messaggio: "Message",
    bottone: "Send"
}

export const infoContattiRumeno = {
    titolo: "Contactează-ne",
    email: "Email",
    oggetto: "Subiectul",
    messaggio: "Mesaj",
    bottone: "Trimite"
}


