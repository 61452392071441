import React, {useState} from 'react';
import Navbar from "../../components/universali/Navbar";
import Footer from "../../components/universali/Footer";
import {Link} from "react-router-dom";
import {newVerificationLink} from "../../axios/NewVerificationLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faWarning} from "@fortawesome/free-solid-svg-icons";
import {sendResetPassword} from "../../axios/EditPassword";

const SendNewVerifyLinkPage = () => {

    const [negativeAlert, setNegativeAlert] = useState(false)
    const [positiveAlert, setPositiveAlert] = useState(false)
    const [isPassword, setIsPassword] = useState(false)

    const handleSend = (e) => {
        e.preventDefault()

        const data = {
            username: e.target.username.value
        }

        if (isPassword) {
            sendResetPassword(data).then(() => {
                setNegativeAlert(false)
                setPositiveAlert(true)
                e.target.reset()
            }).catch(() => {
                setNegativeAlert(true)
                setPositiveAlert(false)
            })
        } else {
            newVerificationLink(data).then(() => {
                setNegativeAlert(false)
                setPositiveAlert(true)
                e.target.reset()
            }).catch(error => {
                setNegativeAlert(true)
                setPositiveAlert(false)
            })
        }


    }

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow flex flex-col items-center justify-center bg-my-gray"}>

                {
                    positiveAlert && (
                        <div className={"flex justify-center items-center mb-6"}>
                            <div className={"bg-my-green bg-opacity-10 w-2/3 py-2 px-4 rounded-md flex justify-between"}>
                                <div className={"flex space-x-5 items-center"}>
                                    <FontAwesomeIcon icon={faCheck} className={"text-xl text-my-green font-semibold"}/>
                                    <div>
                                        <p className={"text-my-green underline"}>
                                            Email Inviata con successo, controlla la tua casella di posta
                                        </p>
                                    </div>
                                </div>
                                <div className={"flex items-center cursor-pointer"} onClick={() => setPositiveAlert(false)}>
                                    <FontAwesomeIcon icon={faClose} className={"text-xl text-my-green font-semibold"}/>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    negativeAlert && (
                        <div className={"flex justify-center items-center mb-6"}>
                            <div className={"bg-my-red bg-opacity-10 space-x-10 py-2 px-4 rounded-md flex justify-between"}>
                                <div className={"flex space-x-5 items-center"}>
                                    <FontAwesomeIcon icon={faWarning} className={"text-xl text-my-red font-semibold"}/>
                                    <div>
                                        <p className={"text-my-red underline"}>
                                            Non è stato possibile inviare la mail
                                        </p>
                                    </div>
                                </div>
                                <div className={"flex items-center cursor-pointer"} onClick={() => setNegativeAlert(false)}>
                                    <FontAwesomeIcon icon={faClose} className={"text-xl text-my-red font-semibold"}/>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className={"text-center text-my-blue font-bold text-4xl underline decoration-my-green"}>
                    Richiedi un nuovo Link
                </div>
                <form onSubmit={handleSend} className={"mx-10 lg:w-1/3"}>
                    <div className={"mt-6 shadow p-10 space-y-6 rounded-md bg-white"}>
                        <div>
                            <input
                                name={"username"}
                                type="text"
                                className={"bg-my-gray rounded outline-none py-2 px-4 w-full "}
                                placeholder={"Username"}
                                required
                            />
                        </div>
                        <div className={"flex justify-center space-x-5"}>
                            <button
                                type={"submit"}
                                className={"bg-my-green px-4 py-2 rounded text-my-gray font-semibold hover:bg-my-blue transition duration-300"}>
                                Link di Verifica Email
                            </button>
                            <button
                                type={"submit"}
                                onClick={() => setIsPassword(true)}
                                className={"bg-my-green px-4 py-2 rounded text-my-gray font-semibold hover:bg-my-blue transition duration-300"}>
                                Link Reset Password
                            </button>
                        </div>
                        <div className={"flex justify-center"}>
                            <Link to={"/"}>
                                <p className={"text-my-blue underline hover:text-my-lightblue"}>Torna alla home</p>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
            <Footer/>
        </div>
    );
};

export default SendNewVerifyLinkPage;
