import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {infoContattiInglese, infoContattiItaliano, infoContattiRumeno} from "../../constants/Contatti/InfoContatti";

const ContattiForm = () => {

    const language = useSelector(state => state.languageReducer)
    const [info, setInfo] = useState(infoContattiItaliano)

    const handleEmail = (e) => {
        e.preventDefault()
    }

    useEffect(() => {
        switch (language) {
            case "Italiano":
                setInfo(infoContattiItaliano)
                break
            case "Inglese":
                setInfo(infoContattiInglese)
                break
            case "Rumeno":
                setInfo(infoContattiRumeno)
                break
            default:
                setInfo(infoContattiItaliano)
        }
    }, [language])

    return (
        <div className={"flex flex-col lg:flex-row justify-center items-center mb-10"}>
            <div className={"flex-1"}/>
            <div className={"flex-2"}>
                <div className={"text-center text-my-blue font-bold text-4xl underline decoration-my-green"}>
                    {info.titolo}
                </div>
                <form onSubmit={handleEmail} className={"mx-10"}>
                    <div className={"mt-6 shadow p-10 space-y-6 rounded-md bg-white"}>
                        <div className={"flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0"}>
                            <div className={"flex-1"}>
                                <input
                                    name={"email"}
                                    type="text"
                                    className={"bg-my-gray rounded outline-none py-2 px-4 w-full "}
                                    placeholder={info.email}
                                />
                            </div>
                            <div className={"flex-1"}>
                                <input
                                    name={"oggetto"}
                                    type="text"
                                    className={"bg-my-gray rounded outline-none py-2 px-4 w-full "}
                                    placeholder={info.oggetto}
                                />
                            </div>
                        </div>

                        <div>
                            <textarea
                                name={"messaggio"}
                                className={"bg-my-gray rounded outline-none py-2 px-4 w-full"}
                                placeholder={info.messaggio}
                            />
                        </div>
                        <div className={"flex justify-center"}>
                            <button
                                type={"submit"}
                                className={"bg-my-green px-4 py-2 rounded text-my-gray font-semibold hover:bg-my-blue transition duration-300"}>
                                {info.bottone}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className={"flex-1"}/>
        </div>
    );
};

export default ContattiForm;
