import React, {useEffect} from 'react';
import Navbar from "../../components/universali/Navbar";
import Footer from "../../components/universali/Footer";
import Table from "../../components/dashboard/Table";
import TabellaCorsi from "../../components/dashboard/TabellaCorsi";
import {Link} from "react-router-dom";

const Dashboard = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow"}>
                <div className={"flex flex-col lg:flex-row mt-10"}>
                    <div className={"flex-1"}>
                        <h1 className={"text-center text-4xl font-bold"}>Iscritti alla Piattaforma</h1>
                        <Table/>
                    </div>
                    <div className={"flex-1"}>
                        <h1 className={"text-center text-4xl font-bold"}>Corsi Disponibili</h1>
                        <TabellaCorsi/>
                    </div>
                </div>
                <div className={"flex justify-center items-center mb-10"}>
                    <div className={"flex-1 flex justify-center"}>
                        <Link to={"/aggiungi_iscritto"}>
                            <div className={"bg-my-green px-4 py-2 rounded text-white font-semibold hover:bg-my-blue"}>
                                Aggiungi Iscritto
                            </div>
                        </Link>
                    </div>

                    {/*<div className={"flex-1 flex justify-center"}>*/}
                    {/*    <Link to={"/aggiungi_corso"}>*/}
                    {/*        <div className={"bg-my-green px-4 py-2 rounded text-white font-semibold hover:bg-my-blue"}>*/}
                    {/*            Aggiungi Corso*/}
                    {/*        </div>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                </div>


            </div>
            <Footer/>
        </div>
    );
};

export default Dashboard;
