import {URL_BASE} from "./URL_BASE";
import axios from "axios";

export const getCorsi = () => {
    const options = {
        url: `${URL_BASE}corsi/`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
    };

    return axios(options)
}

export const getCorsoSingolo = (id) => {
    const options = {
        url: `${URL_BASE}corsi/corso/${id}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
    };

    return axios(options)
}

export const getCorsiUtente = (email) => {
    const options = {
        url: `${URL_BASE}corsi/corsi/${email}`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
        },
    };

    return axios(options)
}
