import React from 'react';
import GrigliaCorsi from "../../components/corsi/GrigliaCorsi";
import Navbar from "../../components/universali/Navbar";
import Footer from "../../components/universali/Footer";

const CorsiPage = () => {
    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow"}>
                <GrigliaCorsi/>
            </div>
            <Footer/>
        </div>
    );
};

export default CorsiPage;
