import {combineReducers} from "redux";
import logReducer from "./logReducer";
import languageReducer from "./languageReducer";
import tokenReducer from "./tokenReducer";

const rootReducer = combineReducers({
    logReducer,
    languageReducer,
    tokenReducer,
})

export default rootReducer
