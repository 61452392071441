import {URL_BASE} from "./URL_BASE";
import axios from "axios";
import jwtDecode from "jwt-decode";

export const setIscrizionePagata = (corsoId, token) => {
    const userId = jwtDecode(token).user_id

    const t = new Date();
    const date = ('0' + t.getDate()).slice(-2);
    const month = ('0' + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();

    const now = `${year}-${month}-${date}`;

    const options = {
        url: `${URL_BASE}corsi/iscrivi/${token}`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
            user: userId,
            corso: corsoId,
            data_iscrizione: now,
            is_pagato: true
        }
    };

    return axios(options)
}

export const addIscrizioneViaAdmin = (userId, corsoId, token) => {

    const t = new Date();
    const date = ('0' + t.getDate()).slice(-2);
    const month = ('0' + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();

    const now = `${year}-${month}-${date}`;

    const options = {
        url: `${URL_BASE}corsi/iscrivi_via_admin/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${token}`,
        },
        data: {
            user: userId,
            corso: corsoId,
            data_iscrizione: now,
            is_pagato: true
        }
    };

    return axios(options)
}
