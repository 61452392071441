import React from 'react';

const Mappa = () => {
    return (
        <div>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47842.042680089406!2d12.88275502579472!3d41.45814626503121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13250c8761b1d6bf%3A0xfb297c7534f68328!2sCentro%20Medico%20di%20Patologia%20Clinica%20Redi!5e0!3m2!1sit!2sit!4v1645523488208!5m2!1sit!2sit"
                allowFullScreen=""
                loading="lazy"
                className={"w-full h-80"}
            />
        </div>
    );
};

export default Mappa;
