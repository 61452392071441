const logReducer = (state = {logged: false, is_staff: false, is_user: false}, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                logged: true,
                is_staff: false,
                is_user: true,
            }

        case "ADMIN_LOGIN":
            return {
                logged: true,
                is_staff: true,
                is_user: false,
            }

        case "LOGOUT":
            return {
                logged: false,
                is_staff: false,
                is_user: false,
            }

        default:
            return state
    }
}

export default logReducer;
