import React, {useEffect, useState} from 'react';
import Navbar from "../../components/universali/Navbar";
import Footer from "../../components/universali/Footer";
import {addIscrizioneViaAdmin} from "../../axios/AddIscrizione";
import {getCorsi} from "../../axios/GetCorsi";
import {getUsers} from "../../axios/GetUsers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faWarning} from "@fortawesome/free-solid-svg-icons";
import Table from "../../components/dashboard/Table";
import {useSelector} from "react-redux";

const AggiungiIscrittoPage = () => {

    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedCorso, setSelectedCorso] = useState(null)

    const [utenti, setUtenti] = useState([])
    const [corsi, setCorsi] = useState([])

    const [userFilter, setUserFilter] = useState("")
    const [corsoFilter, setCorsoFilter] = useState("")

    const [positive, setPositive] = useState(false)
    const [negative, setNegative] = useState(false)
    const {token} = useSelector(state => state.tokenReducer)

    useEffect(() => {
        getCorsi().then(res => {
            setCorsi(res.data)
        })
        getUsers(token).then(res => {
            setUtenti(res.data)
        })
    }, [])


    const handleIscrivi = (e) => {
        e.preventDefault()
        addIscrizioneViaAdmin(selectedUser.id, selectedCorso.id, token).then(() => {
            setSelectedCorso(null)
            setSelectedUser(null)
            setPositive(true)
            setNegative(false)
        }).catch(() => {
            setPositive(false)
            setNegative(true)
        })
    }

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow"}>
                <div className={"my-10 text-center text-my-blue font-bold text-4xl underline decoration-my-green"}>
                    Iscrivi un utente ad un Corso
                </div>
                {
                    positive && (
                        <div className={"flex justify-center items-center mb-6"}>
                            <div className={"bg-my-green bg-opacity-10 w-2/3 py-2 px-4 rounded-md flex justify-between"}>
                                <div className={"flex space-x-5 items-center"}>
                                    <FontAwesomeIcon icon={faCheck} className={"text-xl text-my-green font-semibold"}/>
                                    <div>
                                        <p className={"text-my-green underline"}>
                                            Utente iscritto con successo
                                        </p>
                                    </div>
                                </div>
                                <div className={"flex items-center cursor-pointer"} onClick={() => setPositive(false)}>
                                    <FontAwesomeIcon icon={faClose} className={"text-xl text-my-green font-semibold"}/>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    negative && (
                        <div className={"flex justify-center items-center mb-6"}>
                            <div className={"bg-my-red bg-opacity-10 w-2/3 py-2 px-4 rounded-md flex justify-between"}>
                                <div className={"flex space-x-5 items-center"}>
                                    <FontAwesomeIcon icon={faWarning} className={"text-xl text-my-red font-semibold"}/>
                                    <div>
                                        <p className={"text-my-red underline"}>
                                            Non è stato iscrivere quell'utente
                                        </p>
                                    </div>
                                </div>
                                <div className={"flex items-center cursor-pointer"} onClick={() => setNegative(false)}>
                                    <FontAwesomeIcon icon={faClose} className={"text-xl text-my-red font-semibold"}/>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className={"flex justify-center"}>
                    <div
                        className={"my-10 w-3/4 flex justify-center rounded flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-5"}>
                        <div className={"flex-1 bg-my-gray space-y-6"}>
                            {/*<div className={"bg-white w-full p-2 rounded"}>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        onChange={(e) => setUserFilter(e.target.value)}*/}
                            {/*        className={"bg-transparent outline-none rounded w-full"}*/}
                            {/*        placeholder={"Cerca Utente"}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className={"overflow-y-scroll h-52"}>*/}
                            {/*    {*/}
                            {/*        utenti.filter(user =>*/}
                            {/*            user.nome.toLowerCase().includes(userFilter.toLowerCase()) ||*/}
                            {/*            user.cognome.toLowerCase().includes(userFilter.toLowerCase()) ||*/}
                            {/*            user.email.toLowerCase().includes(userFilter.toLowerCase())*/}
                            {/*        ).map((u, i) => (*/}
                            {/*            <div*/}
                            {/*                key={i}*/}
                            {/*                className={"flex hover:bg-my-green p-2 rounded"}*/}
                            {/*                onClick={() => setSelectedUser(u)}*/}
                            {/*            >*/}
                            {/*                <div className={"grid grid-cols-3 flex w-full"}>*/}
                            {/*                    <div>*/}
                            {/*                        {u.nome} {u.cognome}*/}
                            {/*                    </div>*/}
                            {/*                    <div>*/}
                            {/*                        {u.email}*/}
                            {/*                    </div>*/}
                            {/*                    <div>*/}
                            {/*                        {u.telefono}*/}
                            {/*                    </div>*/}

                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        ))*/}
                            {/*    }*/}
                            {/*</div>*/}
                            <div className={"flex justify-center mt-6 flex-col"}>
                                <div className={"bg-white rounded z-20 mx-8"}>
                                    <input
                                        type="text"
                                        className={"bg-transparent w-full outline outline-none py-2 px-4 rounded-t-md"}
                                        placeholder={"Filtra"}
                                        onChange={(e) => setUserFilter(e.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col w-full px-8 h-80">
                                    <div className="-my-2 overflow-x-auto no-scrollbar sm:-mx-6 lg:-mx-8">
                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                            <div className="shadow border-b border-my-gray sm:rounded-lg">
                                                <table className="min-w-full divide-y divide-my-gray">
                                                    <thead className="bg-gray-50">
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                                        >
                                                            Nome
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                                        >
                                                            Cognome
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                                        >
                                                            Email
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                                        >
                                                            Telefono
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-my-gray">
                                                    {
                                                        utenti.filter(user =>
                                                            user.nome.toLowerCase().includes(userFilter.toLowerCase()) ||
                                                            user.cognome.toLowerCase().includes(userFilter.toLowerCase()) ||
                                                            user.email.toLowerCase().includes(userFilter.toLowerCase())
                                                        ).map((user) => (
                                                            <tr key={user.id} onClick={() => setSelectedUser(user)} className={"hover:bg-my-green"}>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <div className="text-sm font-medium text-my-blue">{user.nome}</div>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <div className="text-sm font-medium text-my-blue">{user.cognome}</div>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap">
                                                                    <div className="text-sm font-medium text-my-blue">{user.email}</div>
                                                                </td>
                                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-my-blue">
                                                                    {user.telefono}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className={"flex-1 bg-my-gray p-5 space-y-6"}>
                            <div className={"bg-white w-full p-2 rounded"}>
                                <input
                                    type="text"
                                    onChange={(e) => setCorsoFilter(e.target.value)}
                                    className={"bg-transparent outline-none rounded w-full"}
                                    placeholder={"Cerca Corso"}
                                />
                            </div>
                            <div className={"overflow-y-scroll h-52"}>
                                {
                                    corsi.filter(corso =>
                                        corso.nome.toLowerCase().includes(corsoFilter.toLowerCase())
                                    ).map((corso, i) => (
                                        <div
                                            key={i}
                                            className={"flex hover:bg-my-green p-2 rounded"}
                                            onClick={() => setSelectedCorso(corso)}
                                        >
                                            <div>
                                                {corso.nome}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>


                        </div>
                    </div>
                </div>
                <div className={"text-center p-2 mb-4 font-semibold text-my-blue text-3xl"}>
                    Chi stai iscrivendo a quale corso
                </div>
                <div className={"flex justify-center"}>
                    <div className={"mb-10 divide divide-y lg:divide-y-0 lg:divide-x border w-3/4 flex justify-center rounded flex-col lg:flex-row lg:space-y-0 lg:space-x-5"}>
                        <div className={"flex-1 p-2"}>
                            {
                                selectedUser ? selectedUser.nome : "Utente"
                            }
                        </div>
                        <div className={"flex-1 p-2"}>
                            {selectedCorso ? selectedCorso.nome : "Corso"}
                        </div>
                    </div>
                </div>


                <div className={"flex justify-center mb-10"}>
                    <button
                        className={"px-4 py-2 bg-my-green text-white font-semibold hover:bg-my-blue rounded"}
                        onClick={handleIscrivi}
                    >
                        Iscrivi
                    </button>
                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default AggiungiIscrittoPage;
