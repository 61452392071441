import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {InfoAboutHeroInglese, InfoAboutHeroItaliano, InfoAboutHeroRumeno} from "../../constants/About/InfoHero";
import sfondo from "../../assets/images/BgHero.png";

const AboutHero = () => {

    const [info, setInfo] = useState(InfoAboutHeroItaliano)

    const language = useSelector(state => state.languageReducer)

    useEffect(() => {
        switch (language) {
            case "Italiano":
                setInfo(InfoAboutHeroItaliano)
                break
            case "Inglese":
                setInfo(InfoAboutHeroInglese)
                break
            case "Rumeno":
                setInfo(InfoAboutHeroRumeno)
                break
            default:
                setInfo(InfoAboutHeroItaliano)
        }
    }, [language])

    return (
        <div>
            <div className="flex flex-col lg:flex-row justify-between gap-8">
                <div className="w-full lg:w-5/12 flex flex-col justify-center">
                    <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                        {info.titolo}
                    </h1>
                    <p className="font-normal text-base leading-6 text-gray-600">
                        {info.descrizione}
                    </p>
                </div>
                <div className={"flex-2 flex items-center justify-center"}>
                    <div className={""}>
                        <img src={sfondo} alt="" className={"h-96 object-cover"}/>
                    </div>
                    <div className={"absolute z-20"}>
                        <img
                            src="https://i.ibb.co/FhgPJt8/Rectangle-116.png"
                            alt=""
                            className={"h-80 rounded object-cover object-center"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutHero;
