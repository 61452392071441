export const InfoAboutHeroItaliano = {
    titolo: "Su di Noi",
    descrizione: "È un fatto consolidato da tempo che un lettore sarà distratto dal contenuto " +
        "leggibile di una pagina quando osserva il suo layout. Il punto di usare Lorem Ipsum. " +
        "In primo luogo abbiamo concesso a Dio, e con questo il nostro presente statuto ha " +
        "confermato per noi e per i nostri eredi per sempre che la Chiesa inglese sarà libera, " +
        "avrà i suoi diritti integri e le sue libertà inviolate; e noi vorremo che si osservi così; " +
        "che risulta da"
}

export const InfoAboutHeroInglese = {
    titolo: "About Us",
    descrizione: "It is a long established fact that a reader will be distracted " +
        "by the readable content of a page when looking at its layout. " +
        "The point of using Lorem Ipsum.In the first place we have granted to God, " +
        "and by this our present charter confirmed for us and our heirs forever that " +
        "the English Church shall be free, and shall have her rights entire, and her " +
        "liberties inviolate; and we will that it be thus observed; which is apparent from"
}
export const InfoAboutHeroRumeno = {
    titolo: "Lanistas manducare! ",
    descrizione: "Teres itineris tramitem recte quaestios impositio est. " +
        "Est germanus gemna, cesaris. Canis, advena, et byssus. " +
        "Nunquam transferre elevatus. Ecce, galatae! Verpa dexter itineris tramitem est. " +
        "Accentors peregrinatione! Pol, a bene ratione, germanus pulchritudine! " +
        "Cur aonides volare? Cur elevatus peregrinatione? Ubi est flavum bulla? " +
        "Castus, bassus impositios satis aperto de audax, lotus buxum. Est azureus coordinatae, cesaris. " +
        "Pol, a bene mensa. Demolitiones accelerare in altus cubiculum! Boreas rusticus assimilatio est. " +
        "Barbatus, fatalis tatas diligenter carpseris de placidus, audax adiurator. "
}


