import React, {useEffect} from 'react';
import Navbar from "../components/universali/Navbar";
import Footer from "../components/universali/Footer";
import Hero from "../components/home/Hero";
import GrigliaCorsi from "../components/corsi/GrigliaCorsi";
import MetodiDiPagamento from "../components/home/MetodiDiPagamento";
import RegisterForm from "../components/home/RegisterForm";
import {useSelector} from "react-redux";

const HomePage = () => {



    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow"}>
                <Hero/>
                <GrigliaCorsi/>
                {/*<MetodiDiPagamento/>*/}
                <RegisterForm/>
            </div>
            <Footer/>
        </div>
    );
};

export default HomePage;
