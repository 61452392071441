export const navLinksItaliano = [
    {
        id: 1,
        nome: "Home",
        link: "/",
        underline: "hover:decoration-my-green",
    },
    {
        id: 2,
        nome: "About",
        link: "/about",
        underline: "hover:decoration-my-green",
    },
    {
        id: 3,
        nome: "Contatti",
        link: "/contatti",
        underline: "hover:decoration-my-green",
    }
]

export const navLinksInglese = [
    {
        id: 1,
        nome: "Home",
        link: "/",
        underline: "hover:decoration-my-green",
    },
    {
        id: 2,
        nome: "About",
        link: "/about",
        underline: "hover:decoration-my-green",
    },
    {
        id: 3,
        nome: "Contact",
        link: "/contatti",
        underline: "hover:decoration-my-green",
    }
]

export const navLinksRumeno = [
    {
        id: 1,
        nome: "Home",
        link: "/",
        underline: "hover:decoration-my-green",
    },
    {
        id: 2,
        nome: "Despre",
        link: "/about",
        underline: "hover:decoration-my-green",
    },
    {
        id: 3,
        nome: "Contacte",
        link: "/contatti",
        underline: "hover:decoration-my-green",
    }
]
