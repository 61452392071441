export const login = () => {
    return {
        type: "LOGIN"
    }
}
export const logout = () => {
    return {
        type: "LOGOUT"
    }
}

export const adminLogin = () => {
    return {
        type: "ADMIN_LOGIN"
    }
}

export const setToken = (token) => {
    return {
        type: "SET_TOKEN",
        token
    }
}

export const removeToken = () => {
    return {
        type: "REMOVE_TOKEN"
    }
}
