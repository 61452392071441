export const InfoTeam = [
    {
        id: 1,
        nome: "Anna",
        cognome: "Maria",
        foto: "https://i.ibb.co/zHjXqg4/Rectangle-118.png"
    },
    {
        id: 2,
        nome: "Olivia",
        cognome: "Maria",
        foto: "https://i.ibb.co/fGmxhVy/Rectangle-119.png"
    },
    {
        id: 3,
        nome: "Marco",
        cognome: "Maria",
        foto: "https://i.ibb.co/Pc6XVVC/Rectangle-120.png"
    },
    {
        id: 4,
        nome: "Paolo",
        cognome: "Forte",
        foto: "https://i.ibb.co/7nSJPXQ/Rectangle-121.png"
    },
]

export const TeamStoryItaliano = {
    titolo: "La nostra storia",
    descrizione: "È un fatto consolidato da tempo\n" +
        "                    che un lettore sarà distratto dal contenuto leggibile di una pagina quando lo guarda\n" +
        "                    disposizione. Il punto di usare Lorem Ipsum. In primo luogo abbiamo concesso a Dio, e da\n" +
        "                    questo il nostro presente statuto ha confermato per sempre per noi e per i nostri eredi che la Chiesa inglese\n" +
        "                    sarà libera, avrà i suoi diritti integri e le sue libertà inviolate; e noi\n" +
        "                    vorrà che sia così osservato; che risulta da"
}

export const TeamStoryInglese = {
    titolo: "Our Story",
    descrizione: "It is a long established fact\n" +
        "                    that a reader will be distracted by the readable content of a page when looking at its\n" +
        "                    layout. The point of using Lorem Ipsum.In the first place we have granted to God, and by\n" +
        "                    this our present charter confirmed for us and our heirs forever that the English Church\n" +
        "                    shall be free, and shall have her rights entire, and her liberties inviolate; and we\n" +
        "                    will that it be thus observed; which is apparent from"
}


export const TeamStoryRumeno = {
    titolo: "Elogiums unda! ",
    descrizione: "Demolitione de rusticus consilium, locus tus!\n" +
        "                    ecce. cur pulchritudine potus? apolloniatess trabem in tubinga! secundus, peritus elevatuss sed mire vitare de fortis, raptus nuclear vexatum iacere.\n" +
        "                    Est placidus lactea, cesaris. Sunt orexises imitari flavum, velox gloses. Sunt stellaes dignus camerarius, noster tuses.\n" +
        "                    Ecce. Absolutios sunt resistentias de superbus tabes. Cum gemna assimilant, omnes ionicis tormentoes promissio audax, gratis animalises.\n" +
        "                    ubi est dexter uria? regius, bi-color victrixs tandem manifestum de talis, peritus saga.\n" +
        "                    cum adelphis mori, omnes navises locus germanus, azureus tumultumquees.\n"
}
