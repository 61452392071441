import React from 'react';

const Bottone = ({testo}) => {
    return (
        <div>
            <button className={"bg-my-green px-8 py-4 rounded text-my-gray font-semibold text-lg hover:bg-my-blue transition duration-300"}>
                {testo}
            </button>
        </div>
    );
};

export default Bottone;
