import React, {useEffect, useState} from 'react';
import {registerInfoInglese, registerInfoItaliano, registerInfoRumeno} from "../../constants/Home/RegisterInfo";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faWarning} from "@fortawesome/free-solid-svg-icons";
import {userRegister} from "../../axios/RegisterRequest";

const RegisterForm = () => {

    const [info, setInfo] = useState(registerInfoItaliano)
    const language = useSelector(state => state.languageReducer)

    const [positiveAlert, setPositiveAlert] = useState(false)
    const [negativeAlert, setNegativeAlert] = useState({
        status: false,
        message: ""
    })

    useEffect(() => {
        switch (language) {
            case "Italiano":
                setInfo(registerInfoItaliano)
                break
            case "Inglese":
                setInfo(registerInfoInglese)
                break
            case "Rumeno":
                setInfo(registerInfoRumeno)
                break
            default:
                setInfo(registerInfoItaliano)
        }
    }, [language])

    const handleRegister = (e) => {
        e.preventDefault()

        if (e.target.password.value === e.target.password2.value) {
            const data = {
                username: e.target.username.value,
                password: e.target.password.value,
                re_password: e.target.password2.value,
                nome: e.target.nome.value,
                cognome: e.target.cognome.value,
                email: e.target.email.value,
                telefono: e.target.telefono.value,
            }
            userRegister(data).then(() => {
                e.target.reset()
                setNegativeAlert({
                    status: false,
                    message: ""
                })
                setPositiveAlert(true)
            }).catch(error => {
                setNegativeAlert({
                    status: true,
                    message: "Non è stato possibile effettuare la registrazione"
                })
                setPositiveAlert(false)
            })
        } else {
            setNegativeAlert({
                status: true,
                message: "Le due password non coincidono"
            })
            setPositiveAlert(false)
        }
    }

    return (
        <div className={"bg-home-hero"}>

            {
                positiveAlert && (
                    <div className={"flex justify-center items-center mb-6"}>
                        <div className={"bg-my-green bg-opacity-10 w-2/3 py-2 px-4 rounded-md flex justify-between"}>
                            <div className={"flex space-x-5 items-center"}>
                                <FontAwesomeIcon icon={faCheck} className={"text-xl text-my-green font-semibold"}/>
                                <div>
                                    <p className={"text-my-green underline"}>
                                        Registrazione avvenuta con successo
                                    </p>
                                </div>
                            </div>
                            <div className={"flex items-center cursor-pointer"} onClick={() => setPositiveAlert(false)}>
                                <FontAwesomeIcon icon={faClose} className={"text-xl text-my-green font-semibold"}/>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                negativeAlert.status && (
                    <div className={"flex justify-center items-center mb-6"}>
                        <div className={"bg-my-red bg-opacity-10 w-2/3 py-2 px-4 rounded-md flex justify-between"}>
                            <div className={"flex space-x-5 items-center"}>
                                <FontAwesomeIcon icon={faWarning} className={"text-xl text-my-red font-semibold"}/>
                                <div>
                                    <p className={"text-my-red underline"}>
                                        {negativeAlert.message}
                                    </p>
                                </div>
                            </div>
                            <div className={"flex items-center cursor-pointer"} onClick={() => setNegativeAlert(false)}>
                                <FontAwesomeIcon icon={faClose} className={"text-xl text-my-red font-semibold"}/>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className={"text-center text-my-blue font-bold text-4xl underline decoration-my-green"}>
                {info.titolo}
            </div>
            <div className={"grid grid-cols-1 lg:grid-cols-4 py-4 lg:py-10 mx-4 lg:mx-0"}>
                <div/>
                <form onSubmit={handleRegister} className={"mx-4 col-span-2 bg-my-green rounded-md"}>
                    <div className={"shadow p-10 space-y-6 rounded-md"}>
                        <div className={"flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0"}>
                            <div className={"flex-1"}>
                                <input
                                    name={"username"}
                                    type="text"
                                    className={"bg-my-gray rounded outline-none py-2 px-4 w-full "}
                                    placeholder={"Username"}
                                    required
                                />
                            </div>
                            <div className={"flex-1"}>
                                <input
                                    name={"nome"}
                                    type="text"
                                    className={"bg-my-gray rounded outline-none py-2 px-4 w-full "}
                                    placeholder={info.nome}
                                    required
                                />
                            </div>
                            <div className={"flex-1"}>
                                <input
                                    name={"cognome"}
                                    type="text"
                                    className={"bg-my-gray rounded outline-none py-2 px-4 w-full "}
                                    placeholder={info.cognome}
                                    required
                                />
                            </div>
                        </div>
                        <div className={"flex flex-col lg:flex-row space-y-6 lg:space-x-6 lg:space-y-0"}>
                            <div className={"flex-1"}>
                                <input
                                    name={"email"}
                                    type="text"
                                    className={"bg-my-gray rounded outline-none py-2 px-4 w-full "}
                                    placeholder={info.email}
                                    required
                                />
                            </div>
                            <div className={"flex-1"}>
                                <input
                                    name={"telefono"}
                                    type="text"
                                    className={"bg-my-gray rounded outline-none py-2 px-4 w-full "}
                                    placeholder={"Telefono"}
                                    required
                                />
                            </div>
                        </div>
                        <div>
                            <input
                                type={"password"}
                                name={"password"}
                                className={"bg-my-gray rounded outline-none py-2 px-4 w-full"}
                                placeholder={info.password}
                                required
                            />
                        </div>
                        <div>
                            <input
                                type={"password"}
                                name={"password2"}
                                className={"bg-my-gray rounded outline-none py-2 px-4 w-full"}
                                placeholder={info.password2}
                                required
                            />
                        </div>
                        <div className={"flex justify-center"}>
                            <button
                                type={"submit"}
                                className={"bg-my-blue px-4 py-2 rounded text-my-gray font-semibold hover:bg-my-gray hover:text-my-blue transition duration-300"}>
                                {info.titolo}
                            </button>
                        </div>
                    </div>
                </form>
                <div/>
            </div>
        </div>

    );
};

export default RegisterForm;
