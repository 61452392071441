import React from 'react';
import Navbar from "../components/universali/Navbar";
import Footer from "../components/universali/Footer";
import Mappa from "../components/contatti/Mappa";
import InfoContatti from "../components/contatti/InfoContatti";
import ContattiForm from "../components/contatti/ContattiForm";

const ContattiPage = () => {
    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-20 flex-grow"}>
                <div className={"mt-4"}>
                    <Mappa/>
                    <InfoContatti/>
                    <ContattiForm/>
                </div>

            </div>
            <Footer/>
        </div>
    );
};

export default ContattiPage;
