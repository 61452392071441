import React, {useEffect, useState} from 'react';
import {getUsers} from "../../axios/GetUsers";
import {useSelector} from "react-redux";

const Table = () => {

    const [users, setUsers] = useState([])
    const [filter, setFilter] = useState("")
    const {token} = useSelector(state => state.tokenReducer)

    useEffect(() => {
        getUsers(token).then(response => {
            setUsers(response.data)
        })
    }, [])

    return (
        <div className={"flex justify-center mt-10 flex-col"}>
            <div className={"bg-my-gray z-20 mx-8"}>
                <input
                    type="text"
                    className={"bg-transparent w-full outline outline-none py-2 px-4 rounded-t-md"}
                    placeholder={"Filtra"}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </div>
            <div className="flex flex-col w-full px-8 h-80">
                <div className="-my-2 overflow-x-auto no-scrollbar sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow border-b border-my-gray sm:rounded-lg">
                            <table className="min-w-full divide-y divide-my-gray">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Nome
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Cognome
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Email
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Telefono
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-my-gray">
                                {
                                    users.filter(user =>
                                        user.nome.toLowerCase().includes(filter.toLowerCase()) ||
                                        user.cognome.toLowerCase().includes(filter.toLowerCase()) ||
                                        user.email.toLowerCase().includes(filter.toLowerCase())
                                    ).map((user) => (
                                        <tr key={user.id}>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-medium text-my-blue">{user.nome}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-medium text-my-blue">{user.cognome}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-medium text-my-blue">{user.email}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-my-blue">
                                                {user.telefono}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Table;
