import axios from "axios";
import {URL_BASE} from "./URL_BASE";
import cookie from "cookie"

export const userLogin = (data) => {
    const options = {
        url: `${URL_BASE}auth/login/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: data
    };

    return axios(options)
}

export const testLogin = async (req, res) => {
    if (req.method === "POST") {
        const {email, password} = res.body

        const data = JSON.stringify({
            email, password
        })

        try {
            const apiRes = await fetch(`${URL_BASE}auth/login/`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: data
            })

            const actualResponse = await apiRes.json()

            if (apiRes.status === 200) {
                res.setHeader("Set-Cookie", [
                    cookie.serialize(
                        "token", actualResponse.access, {
                            httpOnly: true,
                            maxAge: 60*60*24*5
                        }
                    )
                ])
                return res.status(200).json({
                    success: "Login effettuato"
                })
            }
        } catch (err) {
            return res.status(400).json({
                success: "Problema"
            })
        }
    }
}
