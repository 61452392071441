import React, {useEffect, useState} from 'react';
import sfondo from "../../assets/images/BgHero.png"
import {useSelector} from "react-redux";
import {homeHeroInfoInglese, homeHeroInfoItaliano, homeHeroInfoRumeno} from "../../constants/Home/HomeHeroInfo";
import Bottone from "../universali/Bottone";
import {Link} from "react-router-dom";
import persona from "../../assets/images/PersonaSenzaSfondo.png"

const Hero = () => {

    const language = useSelector(state => state.languageReducer)
    const [info, setInfo] = useState(homeHeroInfoItaliano)

    useEffect(() => {
        switch (language) {
            case "Italiano":
                setInfo(homeHeroInfoItaliano)
                break
            case "Inglese":
                setInfo(homeHeroInfoInglese)
                break
            case "Rumeno":
                setInfo(homeHeroInfoRumeno)
                break
            default:
                setInfo(homeHeroInfoItaliano)
        }
    }, [language])

    return (
        <div className={"py-4 px-10 flex flex-col lg:flex-row bg-my-gray"}>
            <div className={"flex-1 p-10"}>
                <div className={"flex flex-col space-y-6 justify-center items-center lg:justify-start lg:items-start"}>
                    <p className={"uppercase font-semibold text-my-green"}>
                        {info.topTitle}
                    </p>
                    <h1 className={"font-bold text-my-blue text-5xl"}>
                        {info.title}
                    </h1>
                    <p>
                        {info.subtitle}
                    </p>
                    <Link to={"/corsi"}>
                        <Bottone testo={info.button}/>
                    </Link>


                </div>
            </div>
            <div className={"flex-2 flex items-center justify-center "}>
                    <div className={""}>
                        <img src={sfondo} alt="" className={"h-96 object-cover"}/>
                    </div>
                    <div className={"absolute z-20"}>
                        <img
                            src={persona}
                            alt=""
                            className={"h-80 rounded"}
                        />
                    </div>
            </div>
        </div>
    );
};

export default Hero;
