import React, {useEffect, useState} from 'react';
import {getCorsi} from "../../axios/GetCorsi";
import {Link} from "react-router-dom";

const TabellaCorsi = () => {


    const [corsi, setCorsi] = useState([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        getCorsi().then(response => {
            setCorsi(response.data)
        })
    }, [])


    return (
        <div className={"flex justify-center my-10 flex-col"}>
            <div className={"bg-my-gray  z-20 mx-8"}>
                <input
                    type="text"
                    className={"bg-transparent w-full outline outline-none py-2 px-4 rounded-t-md"}
                    placeholder={"Filtra"}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </div>
            <div className="flex flex-col w-full px-8 h-80">
                <div className="-my-2 overflow-x-auto no-scrollbar sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow border-b border-my-gray sm:rounded-lg">
                            <table className="min-w-full divide-y divide-my-gray">
                                <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Nome Corso
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Docente
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Costo
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-my-blue uppercase tracking-wider"
                                    >
                                        Info
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-my-gray">
                                {
                                    corsi.filter(corso =>
                                        corso.nome.toLowerCase().includes(filter.toLowerCase())
                                    ).map((corso, index) => (
                                        <tr key={index}>

                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div
                                                    className="text-sm font-medium text-my-blue">{corso.nome}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div
                                                    className="text-sm font-medium text-my-blue">{corso.docente}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div
                                                    className="text-sm font-medium text-my-blue">€{corso.costo}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">

                                                <Link to={`/gestione_corso/${corso.id}`}>
                                                    <div
                                                        className="text-sm font-semibold text-my-green underline cursor-pointer">Gestisci
                                                    </div>
                                                </Link>
                                            </td>

                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabellaCorsi;
