import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faWarning} from "@fortawesome/free-solid-svg-icons";
import {userLogin} from "../axios/LoginRequest";
import jwtDecode from "jwt-decode";
import {adminLogin, login, setToken} from "../redux/actions/logActions";

const LoginPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [alert, setAlert] = useState(false)

    const handleLogin = (e) => {
        e.preventDefault()

        const data = {
            username: e.target.username.value,
            password: e.target.password.value,
        }

        userLogin(data).then(response => {
            const token = response.data.access
            const user = jwtDecode(token)
            if (user.is_verified) {
                if (user.is_staff) {
                    dispatch(adminLogin())
                    dispatch(setToken(token))
                } else {
                    dispatch(login())
                    dispatch(setToken(token))
                }
                navigate("/")
            } else {
                setAlert(true)
            }
        }).catch(() => {
            setAlert(true)
        })
    }

    return (
        <div className={"flex flex-col lg:flex-row justify-center bg-my-gray items-center h-screen"}>
            <div className={"flex-1"}/>
            <div className={"flex-2"}>

                {
                    alert && (
                        <div className={"flex justify-center items-center mb-6"}>
                            <div className={"bg-my-red bg-opacity-10 w-2/3 py-2 px-4 rounded-md flex justify-between"}>
                                <div className={"flex space-x-5 items-center"}>
                                    <FontAwesomeIcon icon={faWarning} className={"text-xl text-my-red font-semibold"}/>
                                    <div>
                                        <p className={"text-my-red underline"}>
                                            Errore di autenticazione, controlla di aver verificato la tua email
                                        </p>
                                    </div>
                                </div>
                                <div className={"flex items-center cursor-pointer"} onClick={() => setAlert(false)}>
                                    <FontAwesomeIcon icon={faClose} className={"text-xl text-my-red font-semibold"}/>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className={"text-center text-my-blue font-bold text-4xl underline decoration-my-green"}>
                    Login
                </div>
                <form onSubmit={handleLogin} className={"mx-10"}>
                    <div className={"mt-6 shadow p-10 space-y-6 rounded-md bg-white"}>
                        <div>
                            <input
                                name={"username"}
                                type="text"
                                className={"bg-my-gray rounded outline-none py-2 px-4 w-full "}
                                placeholder={"Username"}
                                required
                            />
                        </div>
                        <div>
                            <input
                                name={"password"}
                                type="password"
                                className={"bg-my-gray rounded outline-none py-2 px-4 w-full"}
                                placeholder={"Password"}
                                required
                            />
                        </div>
                        <div className={"flex justify-center"}>
                            <button
                                type={"submit"}
                                className={"bg-my-green px-4 py-2 rounded text-my-gray font-semibold hover:bg-my-blue transition duration-300"}>
                                Accedi
                            </button>
                        </div>
                        <div className={"flex justify-center"}>
                            <Link to={"/"}>
                                <p className={"text-my-blue underline hover:text-my-lightblue"}>Torna alla home</p>
                            </Link>
                        </div>
                        <div className={"flex justify-center"}>
                            <Link to={"/new_link"}>
                                <p className={"text-my-blue text-center"}>
                                    <span className={"underline hover:text-my-lightblue"}>Nuovo link verifica email</span> o <span className={"underline hover:text-my-lightblue"}>Reset Password</span>
                                </p>
                            </Link>

                        </div>
                    </div>
                </form>
            </div>
            <div className={"flex-1"}/>
        </div>
    );
};

export default LoginPage;
