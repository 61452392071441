import React from 'react';

const CardCorso = ({img, tag, price, name, nomeProf, profilePic}) => {
    return (
        <div className={"shadow rounded bg-my-gray"}>
            <img
                src={img}
                className={"rounded-t"}
                alt=""
            />
            <div className={"p-6 flex justify-between"}>
                <div className={"bg-my-green bg-opacity-20 px-2 py-1 rounded text-my-green font-semibold"}>
                    {tag}
                </div>
                {/*<div className={"font-semibold text-my-blue"}>*/}
                {/*    €{price}*/}
                {/*</div>*/}
            </div>
            <div className={"px-6 pb-6 text-my-blue text-xl font-semibold"}>
                {name}
            </div>
            <div className={"px-6 pb-6 flex items-center"}>
                <div>
                    <img
                        src={profilePic}
                        alt=""
                        className={"h-10 w-10 object-cover object-center rounded-full"}/>
                </div>
                <div className={"ml-4 "}>
                    {nomeProf}
                </div>
            </div>
        </div>
    );
};

export default CardCorso;
