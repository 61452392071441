import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import bandieraItalia from "../../assets/svg/it.svg"
import bandieraInglese from "../../assets/svg/gb.svg"
import bandieraRumena from "../../assets/svg/ro.svg"
import {setLanguage} from "../../redux/actions/languageActions";

const SelectorButton = () => {

    const language = useSelector(state => state.languageReducer)
    const dispatch = useDispatch()
    const [flag, setFlag] = useState(bandieraItalia)
    const [show, setShow] = useState(false);

    const handleChangeLanguage = (e, lingua) => {
        e.preventDefault()
        setShow(!show)
        switch (lingua) {
            case "Italiano":
                setFlag(bandieraItalia)
                dispatch(setLanguage("ITALIANO"))
                break
            case "Inglese":
                setFlag(bandieraInglese)
                dispatch(setLanguage("INGLESE"))
                break
            case "Rumeno":
                setFlag(bandieraRumena)
                dispatch(setLanguage("RUMENO"))
                break
            default:
                dispatch(setLanguage("ITALIANO"))
                setFlag(bandieraItalia)
        }
    }

    useEffect(() => {
        switch (language) {
            case "Italiano":
                setFlag(bandieraItalia)
                break
            case "Inglese":
                setFlag(bandieraInglese)
                break
            case "Rumeno":
                setFlag(bandieraRumena)
                break
            default:
                setFlag(bandieraItalia)
        }
    }, [language])

    return (
        <div className="relative">
            <div
                className="bg-white flex justify-between border rounded w-40"
                onClick={() => setShow(!show)}
            >
                <div className="px-3 py-3 text-my-blue text-sm leading-3 tracking-normal font-normal">
                    <div className={"flex items-center"}>
                        <img src={flag} alt="" className={"h-3"}/>
                        <span className="ml-2 font-normal">{language}</span>
                    </div>
                </div>
                <div className="bg-white items-center flex rounded-r border-gray-300 border-l">
                    <div className="cursor-pointer text-my-blue mx-3">
                        {
                            show ? (
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="icon icon-tabler icon-tabler-chevron-up" width={20} height={20}
                                     viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                     strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z"/>
                                    <polyline points="6 15 12 9 18 15"/>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="icon icon-tabler icon-tabler-chevron-up" width={20} height={20}
                                     viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                     strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z"/>
                                    <polyline points="6 9 12 15 18 9"/>
                                </svg>
                            )}
                    </div>
                </div>
            </div>
            {
                show && (
                    <ul className="visible transition duration-300 opacity-100 bg-white shadow rounded mt-2 py-1 w-48 absolute">
                        <li
                            className="cursor-pointer text-my-blue text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center"
                            onClick={(e) => handleChangeLanguage(e, "Italiano")}
                        >
                            <div className={"flex items-center"}>
                                <img src={bandieraItalia} alt="" className={"h-3"}/>
                                <span className="ml-2 font-normal">Italiano</span>
                            </div>
                        </li>
                        <li
                            className="cursor-pointer text-my-blue text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center"
                            onClick={(e) => handleChangeLanguage(e, "Inglese")}
                        >
                            <div className={"flex items-center"}>
                                <img src={bandieraInglese} alt="" className={"h-3"}/>
                                <span className="ml-2 font-normal">Inglese</span>
                            </div>
                        </li>
                        <li
                            className="cursor-pointer text-my-blue text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 flex items-center"
                            onClick={(e) => handleChangeLanguage(e, "Rumeno")}
                        >
                            <div className={"flex items-center"}>
                                <img src={bandieraRumena} alt="" className={"h-3"}/>
                                <span className="ml-2 font-normal">Rumeno</span>
                            </div>
                        </li>
                    </ul>
                )}
        </div>
    );
};

export default SelectorButton;
