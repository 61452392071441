import {URL_BASE} from "./URL_BASE";
import axios from "axios";

export const verifyToken = (token) => {
    const options = {
        url: `${URL_BASE}auth/token/verify/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {token: token}
    };

    return axios(options)
}
