import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Navbar from "../components/universali/Navbar";
import Footer from "../components/universali/Footer";
import axios from "axios";
import {URL_BASE} from "../axios/URL_BASE";
import {changePassword} from "../axios/EditPassword";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faWarning} from "@fortawesome/free-solid-svg-icons";

const CambiaPasswordPage = () => {

    const {token, uidb64} = useParams()
    const [isValid, setIsValid] = useState(false)
    const [positiveAlert, setPositiveAlert] = useState(false)
    const [negativeAlert, setNegativeAlert] = useState({
        status: false,
        message: ""
    })

    const handleChangePassword = (e) => {
        e.preventDefault()
        if (isValid) {
            if (e.target.password.value === e.target.password2.value) {
                const data = {
                    password: e.target.password.value,
                    re_password: e.target.password2.value,
                    token: token,
                    uidb64: uidb64
                }
                changePassword(data).then(() => {
                    setPositiveAlert(true)
                    setNegativeAlert({
                        status: false,
                        message: ""
                    })
                    e.target.password.value = ""
                    e.target.password2.value = ""
                }).catch(err => {
                    setNegativeAlert({
                        status: true,
                        message: "Il token non è più valido, richiedine un'altro"
                    })
                    setPositiveAlert(false)
                })
            } else {
                setNegativeAlert({
                    status: true,
                    message: "Le due password non coincidono"
                })
                setPositiveAlert(false)
            }
        }
    }

    useEffect(() => {
        axios.get(`${URL_BASE}auth/password-reset/${uidb64}/${token}`).then(() => {
            setIsValid(true)
        }).catch(() => {
            setIsValid(false)
        })
        console.log(isValid)
    }, [])

    return (
        <div className={"flex flex-col min-h-screen"}>
            <Navbar/>
            <div className={"mt-32 flex-grow flex flex-col justify-center"}>

                {
                    positiveAlert && (
                        <div className={"flex justify-center items-center mb-6"}>
                            <div className={"bg-my-green bg-opacity-10 w-2/3 py-2 px-4 rounded-md flex justify-between"}>
                                <div className={"flex space-x-5 items-center"}>
                                    <FontAwesomeIcon icon={faCheck} className={"text-xl text-my-green font-semibold"}/>
                                    <div>
                                        <p className={"text-my-green underline"}>
                                            Cambio password avvenuto con successo
                                        </p>
                                    </div>
                                </div>
                                <div className={"flex items-center cursor-pointer"} onClick={() => setPositiveAlert(false)}>
                                    <FontAwesomeIcon icon={faClose} className={"text-xl text-my-green font-semibold"}/>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    negativeAlert.status && (
                        <div className={"flex justify-center items-center mb-6"}>
                            <div className={"bg-my-red bg-opacity-10 w-2/3 py-2 px-4 rounded-md flex justify-between"}>
                                <div className={"flex space-x-5 items-center"}>
                                    <FontAwesomeIcon icon={faWarning} className={"text-xl text-my-red font-semibold"}/>
                                    <div>
                                        <p className={"text-my-red underline"}>
                                            {negativeAlert.message}
                                        </p>
                                    </div>
                                </div>
                                <div className={"flex items-center cursor-pointer"} onClick={() => setNegativeAlert(false)}>
                                    <FontAwesomeIcon icon={faClose} className={"text-xl text-my-red font-semibold"}/>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className={"text-center text-my-blue font-bold text-4xl underline decoration-my-green"}>
                    Inserisci una nuova password
                </div>
                <div className={"grid grid-cols-1 lg:grid-cols-4 py-4 lg:py-10 mx-4 lg:mx-0"}>
                    <div/>
                    <form onSubmit={handleChangePassword} className={"mx-4 col-span-2 bg-my-green rounded-md"}>
                        <div className={"shadow p-10 space-y-6 rounded-md"}>
                            <div>
                                <input
                                    type={"password"}
                                    name={"password"}
                                    className={"bg-my-gray rounded outline-none py-2 px-4 w-full"}
                                    placeholder={"Password"}
                                    required
                                />
                            </div>
                            <div>
                                <input
                                    type={"password"}
                                    name={"password2"}
                                    className={"bg-my-gray rounded outline-none py-2 px-4 w-full"}
                                    placeholder={"Ripeti Password"}
                                    required
                                />
                            </div>
                            <div className={"flex justify-center"}>
                                <button
                                    type={"submit"}
                                    className={"bg-my-blue px-4 py-2 rounded text-my-gray font-semibold hover:bg-my-gray hover:text-my-blue transition duration-300"}>
                                    Cambia Password
                                </button>
                            </div>
                        </div>
                    </form>
                    <div/>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CambiaPasswordPage;
