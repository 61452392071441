export const registerInfoItaliano = {
    titolo: "Registrati",
    nome: "Nome",
    cognome: "Cognome",
    email: "Email",
    password: "Password",
    password2: "Ripeti Password",
}

export const registerInfoInglese = {
    titolo: "Register",
    nome: "Name",
    cognome: "Surname",
    email: "Email",
    password: "Password",
    password2: "Repeat Password",
}

export const registerInfoRumeno = {
    titolo: "Brodium",
    nome: "Nome",
    cognome: "Cognome",
    email: "Email",
    password: "Password",
    password2: "Repeta Password",
}
