import profilo from "../../assets/images/Office.png"
import ufficio from "../../assets/images/Office.png"

export const courseCardsItaliano = [
    {
        id: 1,
        name: "Programmazione C/C++",
        price: "59.99",
        tag: "Programmazione",
        immagineCorso: ufficio,
        prof: {
            profilePic: profilo,
            name: "Paolo Forte"
        }
    },
    {
        id: 2,
        name: "Italiano B1",
        price: "29.99",
        tag: "Lingua",
        immagineCorso: ufficio,
        prof: {
            profilePic: profilo,
            name: "Paolo Forte"
        }
    },
    {
        id: 3,
        name: "Italiano B2",
        price: "49.99",
        tag: "Lingua",
        immagineCorso: ufficio,
        prof: {
            profilePic: profilo,
            name: "Paolo Forte"
        }
    },
]

export const courseCardsInglese = [
    {
        id: 1,
        name: "Programming C/C++",
        price: "59.99",
        tag: "Coding",
        immagineCorso: ufficio,
        prof: {
            profilePic: profilo,
            name: "Paolo Forte"
        }
    },
    {
        id: 2,
        name: "Italian B1",
        price: "29.99",
        tag: "Language",
        immagineCorso: ufficio,
        prof: {
            profilePic: profilo,
            name: "Paolo Forte"
        }
    },
    {
        id: 3,
        name: "Italian B2",
        price: "49.99",
        tag: "Language",
        immagineCorso: ufficio,
        prof: {
            profilePic: profilo,
            name: "Paolo Forte"
        }
    },
]

export const courseCardsRumeno = [
    {
        id: 1,
        name: "Rumeno C/C++",
        price: "59.99",
        tag: "Coding",
        immagineCorso: ufficio,
        prof: {
            profilePic: profilo,
            name: "Paolo Forte"
        }
    },
    {
        id: 2,
        name: "Rumeno B1",
        price: "29.99",
        tag: "Language",
        immagineCorso: ufficio,
        prof: {
            profilePic: profilo,
            name: "Paolo Forte"
        }
    },
    {
        id: 3,
        name: "Rumeno B2",
        price: "49.99",
        tag: "Language",
        immagineCorso: ufficio,
        prof: {
            profilePic: profilo,
            name: "Paolo Forte"
        }
    },
]
