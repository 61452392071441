import React, {useEffect, useState} from 'react';
import CardCorso from "./CardCorso";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getCorsi} from "../../axios/GetCorsi";


const GrigliaCorsi = () => {
    const language = useSelector(state => state.languageReducer)
    const titoli = ["I Nostri Corsi", "Our Courses", "Cursurile noastre"]
    const [course, setCourse] = useState([])

    useEffect(() => {
        getCorsi().then(response => {
            setCourse(response.data)
        })
    }, [])

    return (
        <div className={"mt-10"}>
            <div className={"text-center text-my-blue font-bold text-4xl underline decoration-my-green"}>
                {
                    language === "Italiano" ? (
                        titoli[0]
                    ) : language === "Inglese" ? (
                        titoli[1]
                    ) : (
                        titoli[2]
                    )
                }
            </div>
            <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 m-10 lg:px-20"}>
                {
                    course.map(card => (
                        <Link to={`/corsi/${card.id}`} key={card.id}>
                            <div>
                                <CardCorso
                                    img={card.immagine_corso}
                                    name={card.nome}
                                    nomeProf={card.docente}
                                    profilePic={card.immagine_docente}
                                    price={card.costo}
                                    tag={card.categoria}
                                />
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    );
};

export default GrigliaCorsi;
