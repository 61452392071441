import {URL_BASE} from "./URL_BASE";
import axios from "axios";

export const sendResetPassword = (data) => {
    const options = {
        url: `${URL_BASE}auth/request-reset-email/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: data
    };

    return axios(options)
}

export const changePasswordLoggedUser = (data, token) => {
    const options = {
        url: `${URL_BASE}auth/request-reset-user-logged/`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${token}`,
        },
        data: data
    };
    return axios(options)
}


export const changePassword = (data) => {
    const options = {
        url: `${URL_BASE}auth/password-reset-complete/`,
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: data
    };
    return axios(options)
}


export const requestTokenUidb64 = (email) => {
    const options = {
        url: `${URL_BASE}auth/request-reset-user-logged/`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {email: email}
    };

    return axios(options)
}
